import Axios from "axios";
import { number } from "yup";
// const base_Url = "https://stage-oms.thesaicomputers.com/oms";
// const base_Url = "http://localhost:8088";
// const base_Url = "http://192.168.48.165:8088";
const base_Url = "https://emp-stage.thesaicomputers.com/v1";
// const base_Url = "http://162.147.132.150:8088";

export function Emp_Leave(token, obj_body) {
  let config = {
    headers: {
      Authorization: token,
    },
  };

  let body = {
    REQ_FROM: obj_body.date1,
    REQ_TO: obj_body.date2,
    REQ_TYPE: obj_body.REQ_TYPE,
    NO_DAYS: obj_body.days,
    REASON: obj_body.reason,
    TASKS: obj_body.task,
    HR_REMARK: " ",
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/request/insert-request-details`, body, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Emergency contacts on profile
export function Emergency_contact(token, obj_body) {
  let config = {
    headers: {
      Authorization: token,
    },
  };

  let body = {
    EMG_PRIMARY_NAME: obj_body.primary_name,
    EMG_RELATIONSHIP_PRIMARY: obj_body.primary_relation,
    EMG_PHONE_PRIMARY: obj_body.primary_phone,
    EMG_SECONDARY_NAME: obj_body.secondary_name,
    EMG_RELATIONSHIP_SECONDARY: obj_body.secondary_relation,
    EMG_PHONE_SECONDARY: obj_body.secondary_phone,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/request/emergency-contact`, body, config)
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getEmpData(token, apptype, appStatus, fromDate, toDate) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    if (apptype === undefined || apptype === null) {
      apptype = { value: "" };
    }
    if (appStatus === undefined || appStatus === null) {
      appStatus = { value: "" };
    }
    Axios.get(
      `${base_Url}/request/get-request-data?application_type=${apptype?.value}&application_status=${appStatus?.value}&reqFrom=${fromDate}&reqTo=${toDate}`,
      config
    )
      .then((response) => {
        console.log("response of gert emppp", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Get holidays data
export function getHolidaysData(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/emp-user/getHoliday-data`, config)
      .then((response) => {
        console.log("response of get holidays", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

//Add Holidays
export function AddHolidays(token, obj) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  let body = {
    TITLE: obj.TITLE,
    DATE: obj.DATE,
  };
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/admin/add-holiday`, body, config)
      .then((response) => {
        console.log("adddddddddddd holidays", res);
        return res(response);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Get all emoloyee data
export function getAllEmployeeData(token, filter_id, filter_name, filter_desg) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/admin/show-all-profile?EMP_ID=${filter_id}&EMP_NAME=${filter_name}&EMP_DESG_CODE=${filter_desg}`,
      config
    )
      .then((response) => {
        console.log("response of get all employee data", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

let EMP = localStorage.getItem("DecodedToken");
EMP = JSON.parse(EMP);

// Get emoloyee stats data
export function getEmployeeStatsData(EMP_ID) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/request/leave-stats/${EMP_ID}`)
      .then((response) => {
        console.log("response of get emp stats", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Get admin specific profile
export function getSpecificProfile(token, EMP_ID) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/admin/show-specific-profile/${EMP_ID}`, config)
      .then((response) => {
        console.log("response of specific profile", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Get profile emergency contact
export function getEmergencyContact(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/request/emergency-contact-details`, config)
      .then((response) => {
        console.log("response of emergency contactttttttttttttttt", response);
        return res(response);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// Admin Leave Status
export function LeaveStatus(token, obj, emp_id, req_typ) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  let body = {
    ID: obj.ID,
    STATUS: obj.STATUS,
    REMARK: obj.REMARK,
    NO_OF_DAYS: obj.NO_OF_DAYS,
  };
  return new Promise((res, rej) => {
    Axios.post(
      `${base_Url}/request/update-approval-data/${emp_id}/${req_typ}`,
      body,
      config
    )
      .then((response) => {
        console.log("response", res);
        return res(response);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

//Admin leave details
export function LeaveDetails(token, empName, TYPE, STATUS, FROM, TO) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(
      `${base_Url}/request/get-admin-request-data?employeeName=${empName}&requestType=${TYPE}&requestStatus=${STATUS}&reqFrom=${FROM}&reqTo=${TO}`,
      config
    )
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

//Admin tasks details
export function TaskDetails(token, EMP_ID, REQ_AT) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/request/emp-task-show/${EMP_ID}/${REQ_AT}`, config)
      .then((response) => {
        // console.log("responseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

// export function getDesignations() {
//   return new Promise((res, rej) => {
//     Axios.get(`${base_Url}/static/get-designation`)
//       .then((response) => {
//         console.log("response", res);
//         return res(response.data);
//       })
//       .catch((error) => {
//         console.log("errror");
//       });
//   });
// }

export function getUserProfileData(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/emp-user/employee-profile`, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getUserAreaDeatils(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/user/get-user-profile-details`, config)
      .then((response) => {
        console.log("response", res);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function getOneDesginationDetail(desg) {
  return new Promise((res, rej) => {
    Axios.get(`${base_Url}/static/get-desg-details/${desg}`)
      .then((response) => {
        console.log("response", response);
        return res(response.data);
      })
      .catch((error) => {
        console.log("errror");
      });
  });
}

export function sendOtp(body) {
  console.log("mobb getOtp", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/emp-user/set-forget-password`, body).then(
      (response) => {
        console.log("response", response.data);
        return res(response.data);
      }
    );
  }).catch((error) => {
    console.log("error ", error);
  });
}

export function validateOtp(EMP_OTP_NUM) {
  let body = {
    EMP_OTP_NUM: EMP_OTP_NUM,
  };
  console.log("validation dataaa", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/emp-user/verify-otp`, body).then((response) => {
      console.log("response", response.data);
      return res(response.data);
    });
  }).catch((error) => {
    console.log("error", error);
  });
}

export function veriforgetOtp(EMP_OTP_NUM) {
  let body = {
    OTP: Number(EMP_OTP_NUM.OTP),
  };
  console.log("validation dataaa", body);
  return new Promise((res, rej) => {
    Axios.post(`${base_Url}/emp-user/verify-forget-otp`, body).then(
      (response) => {
        console.log("response", response.data);
        return res(response.data);
      }
    );
  }).catch((error) => {
    console.log("error", error);
  });
}
