import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFTUFJTF9JRCI6InBtQGdtYWlsLmNvbSIsIk1PQklMRV9OTyI6Ijk2OTY5Njk2OTYiLCJERVNHIjoiU1VQRVJWSVNPUiIsIlVTRVJfTkFNRSI6IlByYXRlZWsgUE0iLCJ1c2VyX2lkIjoiVVNSMTAwMDkiLCJpZCI6MTAwMDksIlVTRVJfVFlQRSI6IkNSTSIsIlNUQVRVUyI6IkFDVElWRSIsIkRJU0NPTSI6Ik1lUERDTCIsImlhdCI6MTcyNDY2NDE4MSwiZXhwIjoxNzI0NjcxMzgxfQ.3swF0VRSTtsm3EmYONNHBWNHpz1rQ2dAr7ElZgtJHjE",
    setUserDet: {},
    isSignedIn: false,
    userAreaDetails: "",
  },
  reducers: {
    OnSignIn: (state, action) => {
      state.isSignedIn = true;
      state.token = action.payload;
    },
    onSignOut: (state, action) => {
      state.isSignedIn = false;
      state.token = "";
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserDet: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserAreaDet: (state, action) => {
      state.userAreaDetails = action.payload;
    },
  },
});

// export default authSlice.reducer
export const { OnSignIn, onSignOut, setToken, setUserDet, setUserAreaDet } =
  authSlice.actions;

export default authSlice.reducer;
