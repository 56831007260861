import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { Emp_Leave } from "../../Services/ApiCalling"
import moment from "moment"

const EmployeeVisitModelPopup = ({empData,requestType, apptype, appStatus, fromDate, toDate}) => {

  const [days, setDays] = useState(0)
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  // const [selectOpt, setSelectOpt] = useState('');
  const [reason, setReason] = useState('');
  const minDate1 = new Date();
  const minDate2 = new Date(selectedDate1);

  const token =  localStorage.getItem("LogIn_Token")

  const resetValue = ()=>{
    setSelectedDate1(null);
    setSelectedDate2(null);
    setReason('');
    setDays(0);
  }
  const checkSubmit = ()=>{
    if(selectedDate1 === null ){
      if(selectedDate2 === null){
        if(reason.length <=0 ){
          return true
        }
      }
    }else if(selectedDate2 === null){
      if(reason.length <=0){
        return true
      }else{
        return true
      }
    }else if(reason.length <=0){
      return true
    }else{
      return false
    }
  }
    
  const handleSubmit = async (e) =>{
    e.preventDefault();
     const obj={
      date1: selectedDate1,
      date2: selectedDate2,
      REQ_TYPE: requestType,
      days:  days,
      reason: reason
    }
    resetValue(); 
    await Emp_Leave(token, obj);        
    await empData(token, apptype, appStatus, fromDate, toDate);
  }

  const sunday1 = (selectedDate1)=>{
    const day = selectedDate1.getDay();
    return(day !== 0);
  }
  
  const sunday2 = (selectedDate2)=>{
    const day = selectedDate2.getDay();
    return(day !== 0);
  }

  const handleDateChange1 = (selectedDate1) => {
    setSelectedDate1(selectedDate1);
    
  };

  const handleDateChange2 = (selectedDate2) => {
    if(selectedDate1 !== null && selectedDate1 <= selectedDate2){
      setSelectedDate2(selectedDate2);
      
///////////////////////////////////////////////////
      const dt_arr = dateRange(selectedDate1, selectedDate2);
      const dayNo = []
       dt_arr.map((item, ind) =>{
        dayNo.push(item.getDay())
      })
      const getSunday=[]
      dayNo.map((sun, i)=>{
          if(sun === 0){
            getSunday.push(sun)
          }
      })
//////////////////////////////////////////////////

      let yy = ((selectedDate2 - selectedDate1)/(1000*60*60*24))+1
      let xx = yy - getSunday.length
      setDays(xx);
      
      const dt1 = moment(selectedDate1).format('YYYY-MM-DD');
      setSelectedDate1(dt1);
      const dt2 = moment(selectedDate2).format('YYYY-MM-DD');
      setSelectedDate2(dt2);
    }
    else{
      toast.error("Select From_Date first!");
    }
  };

    /////////////////////////////////////////
    function dateRange(startDate, endDate) {
      const dates = [];
      let currentDate = new Date(startDate);
  
      while (currentDate <= endDate) {
          dates.push(new Date(currentDate)); // Clone the date object
          currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
  }
    /////////////////////////////////////////


  return (
    <>
      <div id="add_Visit" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Official Visit</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetValue}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {/* <div className="input-block mb-3">
                  <label className="col-form-label">
                    Visit Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={Visittype}
                    placeholder="Select"
                    styles={customStyles}
                    onChange={(e)=>{setSelectOpt(e.label);}}
                  />
                </div> */}
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate1}
                      onChange={handleDateChange1}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                      minDate={minDate1}
                      filterDate={sunday1}
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                      minDate={minDate2}
                      filterDate={sunday2}
                      onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" readOnly type="text" placeholder={days}/>
                </div>
                {/* <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div> */}
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Visit Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    value={reason}
                    onChange={(e)=>{setReason(e.target.value);}}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="submit"
                    disabled={checkSubmit()}
                  >
                    Submit
                  </button>
                  <h6  style={checkSubmit() === false ?{display:'none'}:{display:"block"}}
                  >Fill out all the fields before submition
                  </h6>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="edit_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Visit</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Visit Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={Visittype}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    defaultValue={2}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Visit Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    defaultValue={"Going to hospital"}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default EmployeeVisitModelPopup;
