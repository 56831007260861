import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { sendOtp, validateOtp } from "../../Services/ApiCalling";

// import * as Yup from "yup";
import * as yup from "yup";

import OtpInput from "react-otp-input";
import { useFormik } from "formik";

const schema = yup.object({
  OTP: yup
    .string()
    .matches(/^\d{4}$/, "OTP must be 4 digits number")
    .required("Kindly enter OTP")
    .trim(),
});

const Otp = () => {
  const { signIn } = useAuth();
  const [passData, setPassData] = useState({});
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const [otpSentTime, setOtpSendTime] = useState();

  const token = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);

  const { formData } = location.state || {};
  console.log("forget pass data", formData);

  const onSubmit = (values) => {
    handleOtpValidation(values);
    navigate("/login");
    console.log("submitted");
  };

  const handleOtpValidation = async (values) => {

    try {
      const body = {
        enteredOtp: Number(values.OTP),
        MOBILE_NO: formData.MOBILE_NO,
        NEW_PASSWORD: formData.NEW_PASSWORD,
      };
      console.log("bodyyyyyyyyy", body);
      const response = await validateOtp(body);
      console.log("res of otp validation", response);

      if (response.Is_OTP_EXPIRED == "true") {
        alert("OTP EXPIRED");
        setIsDisabled(false);
      } else if (response.Success == "true") {
        console.log(response.Message);
        navigate("/sign-in");
      } else if (response.Success === "false") {
        alert(response.Message);
        console.log("Wrong OTP");
      }

      //const response = await validateOtp(body);
      //console.log('resss' ,response);
    } catch (error) {
      console.log("error in generating otp", error);
    }
  };
  const generateOtp = async (formData) => {
    try {
      console.log("to send data", formData);
      const body = {
        MOBILE_NO: formData.MOBILE_NO,
      };

      const passData = await sendOtp(formData);

      console.log("resss", passData);
      setPassData(passData);
    } catch (error) {
      console.log("error in generating otp", error);
    }
  };

  const initialValues = {
    OTP: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="account-page">
          {/* Main Wrapper */}
          <div className="main-wrapper">
            <div className="account-content">
              <div className="container">
                {/* Account Logo */}
                <div className="account-logo">
                  {/* <Link to="/admin-dashboard"> */}
                  <Link to="/login">
                    <img src={Applogo} alt="Dreamguy's Technologies" />
                  </Link>
                </div>
                {/* /Account Logo */}
                <div className="account-box">
                  <div className="account-wrapper">
                    <h3 className="account-title">OTP</h3>
                    <p className="account-subtitle">
                      Verification your account
                    </p>

                    <div className="otp-wrap  d-flex justify-content-center align-items-center">
                      <OtpInput
                        className="otp-input"
                        value={formik.values.OTP}
                        onChange={(otp) => {
                          formik.setFieldValue("OTP", otp);
                        }}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        // isInputNum:tel
                        renderInput={({ inputType = "tel", ...props }) => (
                          <input
                            {...props}
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              margin: "0 0.5rem",
                              fontSize: "1.5rem",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              textAlign: "center",
                            }}
                            type={inputType}
                          />
                        )}
                      />
                    </div>
                    {formik.errors.OTP && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        {formik.errors.OTP}
                      </div>
                    )}
                    <div className="input-block mb-2 text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit"
                      >
                        Enter
                      </button>
                    </div>
                    <div className="account-footer">
                      <p>
                        Not yet received? <Link to="/otp">Resend OTP</Link>
                      </p>
                    </div>
                    {/* /Account Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Main Wrapper */}
        </div>
      </form>
    </>
  );
};

export default Otp;
