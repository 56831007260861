import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";

const EmployeeList = ({employeeData, setFilter_id, setFilter_name, setFilter_desg, filter_id, filter_name, filter_desg }) => {
  const navigate = useNavigate()

  const columns = [
    {
      title: "Name",
      dataIndex: "EMP_NAME",
      render: (text, record) => (
        <span className="table-avatar" onClick={()=> navigate("/personProfile",{state:record?.EMP_ID})} >
            <img className="avatar" alt="" src={record.image} />
            {text} <span>{record.role}</span>
        </span>
      ),
      sorter: (a, b) => a.EMP_NAME.length - b.EMP_NAME.length,
    },
    {
      title: "Employee ID",
      dataIndex: "EMP_ID",
      sorter: (a, b) => a.EMP_ID.length - b.EMP_ID.length,
    },

    {
      title: "Email",
      dataIndex: "EMP_EMAIL_ID",
      sorter: (a, b) => a.EMP_EMAIL_ID.length - b.EMP_EMAIL_ID.length,
    },

    {
      title: "Mobile",
      dataIndex: "EMP_MOBILE_NO",
      sorter: (a, b) => a.EMP_MOBILE_NO.length - b.EMP_MOBILE_NO.length,
    },

    {
      title: "Designation",
      dataIndex: "EMP_CURR_DESG",
    //   sorter: (a, b) => a.joindate.length - b.joindate.length,
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
          />
          {/* /Page Header */}
          <EmployeeListFilter setFilter_id={setFilter_id} setFilter_name={setFilter_name} setFilter_desg={setFilter_desg}
           filter_id={filter_id} filter_name={filter_name} filter_desg={filter_desg}/>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={employeeData}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* <AllEmployeeAddPopup />
        <DeleteModal Name="Delete Employee" /> */}
      </div>
    </div>
  );
};

export default EmployeeList;
