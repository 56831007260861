
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../user";
import { resetFunctionwithlogin } from "../../../components/ResetFunction";
import useAuth from "../../hooks/useAuth";

// Email regex for validation
const emailrgx = /^[a-zA-Z0-9._%+-]+@thesaicomputers\.com$/;

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailrgx, "Email should end with @thesaicomputers.com")
    .required("Email is required")
    .trim(),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = () => {
  const { signIn } = useAuth();
  // const token = useSelector((state) => state.auth);
  const token =  localStorage.getItem("LogIn_Token")

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eye, setEye] = useState(true);

  const showLoginError=(message)=>{
    toast.error(message , { position: "top-right" });

  }
  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await signIn(values);
        // console.log("tokentoken", res.Body);

        console.log("Response:", res);
        if (res.Error) {
          showLoginError(res.Message);
        }
        // else if(res.Success === "false" && res.message === "user is not active"){
        //   // showLoginError(res.Message);
        //   console.log("sdfghjkl;",res.Success === "false")
        //   alert("not registered")
        // } 
        else {
          dispatch(login(values));
          localStorage.setItem("credencial", JSON.stringify(values));
          navigate("/leaves-employee");
          resetFunctionwithlogin();
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const onEyeClick = () => {
    setEye(!eye);
  };

  return (
    <>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">Access to our dashboard</p>
                  {/* Account Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="input-block mb-4">
                      <label className="col-form-label">Email Address</label>
                      <input
                        className={`form-control ${
                          formik.touched.email && formik.errors.email
                            ? "error-input"
                            : ""
                        }`}
                        type="text"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        autoComplete="true"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className="text-danger">
                          {formik.errors.email}
                        </span>
                      ) : null}
                    </div>
                    <div className="input-block mb-4">
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label">Password</label>
                        </div>
                        <div className="col-auto">
                          <Link className="text-muted" to="/forgot-password">
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <input
                          className={`form-control ${
                            formik.touched.password && formik.errors.password
                              ? "error-input"
                              : ""
                          }`}
                          type={eye ? "password" : "text"}
                          id="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          autoComplete="true"
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "5%",
                            top: "30%",
                          }}
                          onClick={onEyeClick}
                          className={`fa-solid ${
                            eye ? "fa-eye-slash" : "fa-eye"
                          } `}
                        />
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <span className="text-danger">
                          {formik.errors.password}
                        </span>
                      ) : null}
                    </div>
                    <div className="input-block text-center">
                      <button
                        className="btn btn-primary account-btn"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                    <ToastContainer />

                  </form>
                  <div className="account-footer">
                    <p>
                      Don't have an account yet?{" "}
                      <Link to="/register">Register</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
