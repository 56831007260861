/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import moment from "moment";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";

const PersonProfile = ({ profileData, emgData }) => {
    console.log("profffffffiiilleee", profileData.EMP_ID, profileData.EMP_EMAIL_ID)

    const leaveStats = [
        {
            id: 1,
            title: "Total Leave",
            value: profileData.TOTAL_LEAVE,
        },
        {
            id: 2,
            title: "Pending Leave/Current Leave",
            value: profileData.pendingRequestsCount,
        },
        {
            id: 3,
            title: "Remaining Leave",
            value: profileData.REM_LEAVE,
        },
    ];


    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <Breadcrumbs
                        maintitle="Profile"
                        title="Dashboard"
                        subtitle="Profile"
                        subtitle2="Employee"
                        modal="#add_indicator"
                        name="Add New"
                    />


                    <div className="row">
                        {leaveStats.map((stat, index) => (
                            <div className="col-md-4" key={index}>
                                <div className="stats-info">
                                    <h6>{stat.title}</h6>
                                    <h4>{stat.value}</h4>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">

                                        <div className="profile-basic">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="profile-info-left">
                                                        {/* <h3 className="user-name m-t-0 mb-0">
                                                            {profileData.EMP_NAME}
                                                        </h3> */}

                                                        <small className="text-muted">
                                                            {profileData.EMP_CURR_DESG
                                                            }
                                                        </small>
                                                        <div className="staff-id">
                                                            Employee ID : {profileData.EMP_ID
                                                            }
                                                        </div>
                                                        <div className="small doj text-muted">
                                                            Date of Joining : {profileData.EMP_DOJ}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Phone:</div>
                                                            <div className="text">
                                                                <Link to={`tel:${profileData.EMP_MOBILE_NO
                                                                    }`}>
                                                                    {profileData.EMP_MOBILE_NO}
                                                                </Link>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Email:</div>
                                                            <div className="text">
                                                                <Link to={`mailto:${profileData.EMP_EMAIL_ID}`}>
                                                                    {profileData.EMP_EMAIL_ID}
                                                                </Link>
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="pro-edit">
                                            <Link
                                                data-bs-target="#profile_info"
                                                data-bs-toggle="modal"
                                                className="edit-icon"
                                                to="#"
                                            >
                                                <i className="fa-solid fa-pencil"></i>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">
                                        <div className="profile-basic">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <ul className="profile-info">
                                                        <h3 className="user-name m-t-0 mb-0">
                                                            Emergency Primary Contact
                                                        </h3><br/>
                                                        {/* <div className="staff-id">
                                                            Employee ID : {profileData?profileData.EMP_ID:""}
                                                        </div>
                                                        <div className="text">
                                                            <span>Employee Email : </span> 
                                                            <Link to={`mailto:${profileData?profileData.EMP_EMAIL_ID:""}`}>
                                                                {profileData?profileData.EMP_EMAIL_ID:""}
                                                            </Link>
                                                        </div> */}
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Primary Name : {emgData?emgData.EMG_PRIMARY_NAME:""}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Primary Relationship : {emgData?emgData.EMG_RELATIONSHIP_PRIMARY:""}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Primary Phone No : {emgData?emgData.EMG_PHONE_PRIMARY:""}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-7">
                                                    <ul className="personal-info">
                                                        <h3 className="user-name m-t-0 mb-0">
                                                            Emergency Secondary Contact
                                                        </h3><br/>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Secondary Name : {emgData?emgData.EMG_SECONDARY_NAME:""}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Secondary Relationship : {emgData?emgData.EMG_RELATIONSHIP_SECONDARY:""}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Emg Secondary Phone No : {emgData?emgData.EMG_PHONE_SECONDARY:""}
                                                            </div>
                                                        </li>
                                                        {/* <li>
                                                            <div className="small doj text-muted">
                                                                Created At : {moment(emgData?emgData.createdAt:"").format('YYYY-MM-DD HH:mm:ss')}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="small doj text-muted">
                                                                Updated At : {moment(emgData?emgData.updatedAt:"").format('YYYY-MM-DD HH:mm:ss')}
                                                            </div>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="pro-edit">
                                            <Link
                                                data-bs-target="#profile_info"
                                                data-bs-toggle="modal"
                                                className="edit-icon"
                                                to="#"
                                            >
                                                <i className="fa-solid fa-pencil"></i>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <PersonalInformationModelPopup />

                </div>
            </div>
        </>
    );
};

export default PersonProfile;
