import React from "react";
import Register from "./components/Register";

function SignUpView() {
  return (
    <>
      <Register />
    </>
  );
}

export default SignUpView;
