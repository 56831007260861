/* eslint-disable no-unused-expressions */

import { Table } from "antd";
import { AddHoliday } from "../../../components/modelpopup/AddHoliday";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { base_url } from "../../../base_urls";
import moment from 'moment';
import { AddHolidays } from "../../Services/ApiCalling";

const Holidays = ({holidaysData, HolidayData}) => {

  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")

  const token = localStorage.getItem("LogIn_Token")


  const handleSubmit = async () => {
    const obj = {
      TITLE: title,
      DATE: date,
    };

    try {
      console.log("titletitle", title, date)
      const response = await AddHolidays(token, obj);
      console.log("HolidayDataHolidayData", response)
      HolidayData(token)
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "ID",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.ID - b.ID,
    },
    {
      title: "Title",
      dataIndex: "TITLE",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.TITLE.length - b.TITLE.length,
    },
    {
      title: "HolidayDate",
      dataIndex: "HOLIDAY_DATE",
      render: (text) => 
        <span>{moment(text).format('YYYY-MM-DD')}</span>,
      sorter: (a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE),
        
    },
    {
      title: "Day",
      dataIndex: "DAY",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.DAY.length - b.DAY.length,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Holidays"
            title="Dashboard"
            subtitle="Holidays"
            modal="#add_holiday"
            name="Add Holiday"
          />

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {console.log("rashi",holidaysData.length)}
                <Table
                  columns={columns}
                  dataSource={holidaysData?.length > 0 ? holidaysData : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddHoliday setTitle= {setTitle} setDate = {setDate} handleSubmit={handleSubmit}/>
      <DeleteModal Name="Delete Holiday" />
    </>
  );
};

export default Holidays;
